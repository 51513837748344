$input-btn-focus-box-shadow: none !important;
$enable-negative-margins: true;
$gray-100: #f9f9f9 !default;
$gray-800: #353535 !default;
$black:    #000 !default;
$info: #0328A6;
$input-border-color: $black !default;
$input-padding-x: 0px;
$form-label-margin-bottom: 0rem !default;
$form-label-font-size: 12px !default;
$input-bg: transparent !default;
$primary-gradient: $gray-800,
$black;
$border-radius: 6px !default;

@mixin primary-gradient($direction, $primary-gradient) {
      background: -webkit-linear-gradient($direction, $primary-gradient);
      background: -moz-linear-gradient($direction, $primary-gradient);
      background: -o-linear-gradient($direction, $primary-gradient);
      background: linear-gradient($direction, $primary-gradient);
}

@import "bootstrap/bootstrap.scss";
@import "bootstrap-icons.scss";

body,
html {
      min-height: 100%;
      height: 100%;
      position: relative;
      background-color: $gray-100;
}

.map-view {
      width: 100%;
      height: 100%;
      position: relative;
      display: flex;

      &::before {
            background: -moz-linear-gradient(top, rgba(249, 249, 249, 1) 0%, rgba(251, 251, 251, 1) 30%, rgba(255, 255, 255, 0) 100%);
            background: -webkit-linear-gradient(top, rgba(249, 249, 249, 1) 0%, rgba(251, 251, 251, 1) 30%, rgba(255, 255, 255, 0) 100%);
            background: linear-gradient(to bottom, rgba(249, 249, 249, 1) 0%, rgba(251, 251, 251, 1) 30%, rgba(255, 255, 255, 0) 100%);
            filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#f9f9f9', endColorstr='#00ffffff', GradientType=0);
            content: 'Select your Preferred Location to book  Appointment with us';
            text-align: center;
            position: absolute;
            padding: 20px 20px 60px;
            z-index: 1;
            width: 100%;
      }

      iframe {
            width: 100%;
            height: 100%;
            position: absolute;
      }
}

.bg-primary,
.btn-primary,
.badge-primary,
.alert-primary {
    @include primary-gradient(to right, $primary-gradient);
    border-color: $black;
}

.btn{
      text-transform: uppercase;
}

.form-control{
      border-top: 0px;
      border-left: 0px;
      border-right: 0px;
      border-radius: 0px;
}

.service{
      background-color: $gray-100;
      border-radius: $border-radius;
      padding: 10px;
      display: flex;
      position: relative;
      margin-bottom: 15px;
      margin-right: 10px;
      .service-thumn{
            border-radius: $border-radius;
            min-width: 120px;
            height: 100%;
            background-color: $gray-800;
            margin-right: 15px;
      }
      .service-detail{
            min-width: 185px;
            .see-more{
                  width: 16px;
                  align-self: flex-start;
                  margin-left: 10px;
                  margin-top: 8px;
            }
      }
}
nav{
      a{
            align-self: center;
            text-decoration: none;
            padding: 0px 10px;
            color: $black;
      }
}
.custom-checkbox{
      .form-check-input{
            width: 2rem;
            height: 2rem;
      }
      .form-check-input:checked[type="radio"]{
            background-image: url('../images/check.svg');
            background-color: $black;
            background-size: 60%;
      }
}
.circle-img{
      border-radius: 50%;
      overflow: hidden;
      img{
            width: 100%;
      }
}

.img36x36{
      width: 36px;
      height: 36px;
}

.img60x60{
      width: 60px;
      height: 60px;
}

.offcanvas-bottom{
      border-radius: 16px 16px 0px 0px;
}

// Small devices (landscape phones, 576px and up)
@media (max-width: 767px) { 
      .hamburg-icon{
            font-size: 35px;
      }
      nav{
            a{
                  display: block;
                  width: 100%;
                  margin: 20px 0px;
            }
      }
      .logo-mobile{
            width: 190px;
      }
 }

// Medium devices (tablets, 768px and up)
@media (min-width: 768px) { 
      .hamburg-icon{
            font-size: 35px;
      }
 }

// Large devices (desktops, 992px and up)
@media (min-width: 992px) { 

 }

// X-Large devices (large desktops, 1200px and up)
@media (min-width: 1200px) { 

 }

// XX-Large devices (larger desktops, 1400px and up)
@media (min-width: 1400px) { 
      .service{
            .service-thumn{
                  border-radius: $border-radius;
                  min-width: 100px;
                  height: 100%;
                  background-color: $gray-800;
                  margin-right: 15px;
            }
            .service-detail{
                  min-width: 300px;
            }
      }
}