a:link.contactlink {
    --bs-text-opacity: 1;
    color: #0d6efd !important;
}

a:link.contactlink:hover {
    --bs-text-opacity: 1;
    color: #0d6dfdd3 !important;
}

span.p.contactp {
    --bs-text-opacity: 1;
    color: #6c757d !important;
}

a:link {
    color: black !important;
    text-decoration: none;
}

a:hover {
    color: black !important;
    text-decoration: none;
}

a:visited {
    color: black !important;
    text-decoration: none;
}

a:active {
    color: black !important;
    text-decoration: none;
}

.a2cldr .a2cldr-btn {
    width: auto !important;
    height: auto !important;
    display: inline-block;
    border: 2px solid #f8f9fa !important;
    padding: 0px !important;
    text-align: left;
    cursor: pointer;
    box-sizing: border-box;
    font: inherit;
    color: #0328a6 !important;
    background: transparent !important;
    border: #f8f9fa !important;
    border-bottom: 1px solid #0328a6 !important;
}

.a2cldr {
    height: auto !important;
    width: auto !important;
    position: relative !important;
    font-family: sans-serif;
}

.a2cldr.active {
    height: auto !important;
    width: auto !important;
    position: relative !important;
    font-family: sans-serif;
}

.a2cldr.active .a2cldr-btn {
    color: #0328a6 !important;
    background: transparent !important;
    border: #f8f9fa !important;
    border-bottom: 1px solid #0328a6 !important;
}

.alignCalender {
    width: max-content !important;
    margin-left: 1% !important;
}

.a2cldr-list {
    position: absolute !important;
    z-index: 30;
    padding: 0px 12px;
    display: none;
    background-color: #fff;
    box-shadow: 0px 8px 20px 0px #bababa;
}

.textFormat {
    text-transform: none !important;
}

.offcanvas-body {
    flex-grow: 1;
    padding: 1rem 1rem;
    overflow-y: auto;
    height: 400px;
}

.react-datepicker__day--outside-month {
    color: transparent;
    pointer-events: none;
}

.react-datepicker {
    border: 1px solid #fafafa !important;
}

.react-datepicker__navigation-icon::before {
    border-color: black !important;
    top: 45% !important;
}

.react-datepicker__day-names {
    border-color: #212529 !important;
    padding-top: 2% !important;
}

.react-datepicker__day--selected {
    background-color: #0d6efd !important;
}

.react-datepicker__header {
    text-align: center;
    background-color: #f0f0f000 !important;
    border-bottom: 1px solid #aeaeae;
    border-top-left-radius: 0.3rem;
    padding: 8px 0;
    position: relative;
}
