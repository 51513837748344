.a2cldr {
  height: 46px;
  width: 246px;
  position: relative;
  color: #032c53;
  font-family: sans-serif;
}
.a2cldr .a2cldr-btn {
  width: 246px;
  height: 46px;
  display: inline-block;
  border: 2px solid #032c53;
  padding: 12px 18px;
  text-align: left;
  cursor: pointer;
  /*
    -webkit-transition: all .2s ease-in-out;
       -moz-transition: all .2s ease-in-out;
        -ms-transition: all .2s ease-in-out;
         -o-transition: all .2s ease-in-out;
            transition: all .2s ease-in-out;
    */
  box-sizing: border-box;
  /* reset default agent style */
  font: inherit;
  color: inherit;
  background: inherit;
}
.a2cldr .a2cldr-btn:before {
  display: inline-block;
  float: right;
  position: absolute;
  top: 0;
  right: 16px;
  font-size: 24px;
  line-height: 46px;
}
.a2cldr.active .a2cldr-btn {
  color: #fff;
  background-color: #032c53;
}
.a2cldr.active .a2cldr-btn:before {
  font-size: 20px;
  line-height: 44px;
}
.a2cldr.active .a2cldr-list {
  display: block;
}
.a2cldr-list {
  position: relative;
  z-index: 2;
  padding: 0 12px;
  display: none;
  background-color: #fff;
  box-shadow: 0px 8px 20px 0px #bababa;
}
.a2cldr-item {
  list-style: none;
  padding: 14px 0;
  border-bottom: 1px solid #f3f3f3;
}
.a2cldr-item a {
  color: #032c53;
  text-decoration: none;
  width: 100%;
  display: block;
}
.a2cldr-item a:hover {
  color: #032c53;
  text-decoration: underline;
}
.a2cldr-item:last-child {
  border-bottom: 0;
}

